
.subscribe-container {
  background-color: rgb(255, 255, 255, 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 100px 0;
}

.subscribe-form {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.1px);
  -webkit-backdrop-filter: blur(4.1px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 50px;
}
.subscribe-form input[type="email"] {
  width: 100%;
  margin-bottom: 30px; /* Increase margin for better spacing */
  padding: 15px;
  border-radius: 50px;
  font-size: 24px;
  border: 1px solid #007bff; /* Add a blue border */
 
}


.subscribe-form button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

/* Style for the "Get Started" button on hover */
.subscribe-form button:hover {
  background-color: #0056b3;
}

/* Style for the "Sending..." message */
.subscribe-form p {
  margin-top: 20px;
  font-size: 16px;
  color: #007bff; /* Blue color for the message */
}

/* Style for the success message */
.subscribe-form div p {
  margin-top: 20px;
  font-size: 16px;
  color: #4caf50; /* Green color for success message */
}
