
@import '../../App.css';
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.body-content {
  padding-bottom: 0px; /* Adjust the value as needed to provide space for the footer */
}


.slideshow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  text-align: center;
  
}

.slideshow-slide {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  max-width: 100%;
}

.active {
  opacity: 1;
  transition: opacity 0.5s;
}

.slideshow-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}


.centered-text-container {
  position: relative;
  z-index: 10;
}

.slideshow-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, var(--background-color-zero-alpha) 95%, var(--background-color) 100%);
  pointer-events: none;
}
@media (max-width: 600px) {
  .image-overlay .get-started-button {
    display: none;
  }
  .constant-header {
    font-size: 1.3rem !important; 
    padding-top: 20px;
    padding-bottom: 15px !important;
    width: 100%;
    margin: 0 !important;
   
  }

  .dynamic-header {
    font-size: 1rem !important; 
    padding-top: 0 !important;
    padding-top: 10px !important;
    width: 100%;
  }
  .slideshow-container{
    width: 100%;
    
  }
}

.get-started-button:hover {
  transform: scale(1.06); /* Increase the size on hover */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

@media (min-width: 601px) and (max-width: 767px) {
  .constant-header{
    padding-top: 3rem !important;
    font-size:2.9rem !important ;
    padding-bottom: 0 !important;
  }
  .image-overlay .get-started-button {
    font-size: 0.6rem!important; 
    margin-top: 0 !important;
  }
  .dynamic-header{
    font-size: 1.3rem!important;
    padding-top: 15px !important;
  }
}



@media (min-width: 768px) and (max-width: 991px) {
  .constant-header
  {padding-top: 30px !important;
    padding-bottom:0 !important;
    font-size: 3.1rem!important;
}
.dynamic-header{
  font-size: 1.3rem!important;
  padding-top: 30px!important;
}
.image-overlay .get-started-button {
    
  font-size: 1rem!important; 
  padding: 9px !important;
  margin-top: 0!important;
  padding-top: 0;
  border: 1px solid !important;
  
}

}
@media (min-width: 992px)and (max-width: 1199px){

  .image-overlay .get-started-button {
    font-size: 0.9rem!important;
  }
  .dynamic-header{
    font-size: 1.8rem !important;
  }
  
  }