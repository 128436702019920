/* App.css */
:root {
    --background-color: rgb(250, 250, 250);
    --background-color-zero-alpha: rgba(255, 165, 0, 0);
    --font-color:black;
  }

body {
    background-color: var(--background-color);
    color: var(--font-color);
}
  