.serana-logo {
  width: auto;
  height: 60px;
  margin-right: 10px;
}

.tabs {
  margin-left: auto;
}

.tab-link {
  font-size: 1.2rem;
  color: white !important;
  text-decoration: none;
}

.tabs .Mui-selected .tab-link {
  font-weight: bold;
}

.active-tab {
  font-weight: bold !important;
}

/* Media query for smaller screens (e.g., mobile) */
@media only screen and (max-width: 600px) {
  .serana-logo {
    /* Adjust styles for smaller screens */
    height: 40px;
  }

  .tabs {
    /* Adjust styles for smaller screens */
    display: none; /* Hide tabs on smaller screens */
  }

  .menu-icon {
    /* Show hamburger icon on smaller screens */
    display: block !important;
  }
}
