.cardText {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 4.6em;
    font-size: 15px;
    line-height: 1.6; 
    color: #5a6169;
    transition: color 0.3s ease-in-out;
    text-align: justify;
    
}


.cardText p {
    margin: 0; 
}

.grid {
    margin-bottom: 4.5rem!important;
    
}

.grid:nth-child(3n+1) {
    clear: both;
}

.authorimg {
    transform: translateY(50%);
    margin-left: 1.5625rem;
    position: absolute;
    bottom: 0;
    display: flex!important;
}

.authorimg a {
    box-shadow: 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90, 97, 105, 0.5);
    display: block;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    text-indent: -9999px;
    width: 3.1875rem;
    height: 3.1875rem;
}

.textfiordblue {
    color: #3d5170 !important;
    text-decoration: none;
    
    
}

.grid h5 {
    margin-top: 15px;
}



.grid svg {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    width: 0.875em;
    margin-right: 10px;
}

.grid span {
    font-weight: 500;
    font-size: 15px;
    display: inline;
}
.cardsmall {
    position: relative;
    transition: transform 0.3s ease-in-out;
    border-radius: 15px;
  }
  
  .cardsmall:hover {
    transform: translate(5px, 5px); /* Adjust the values for the desired movement */
    box-shadow: 5px 5px 15px rgba(0.3, 0.3, 0.3, 0.3); 
  }
  .cardpost__image {
    position: relative;
    aspect-ratio: 16/9;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    
}
  .cardpost__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .cardsmall:hover .cardpost__overlay {
    opacity: 1;
    
  } 
.blogContentContainer {
    padding: 12px;
    
    
  }
  .blogContentContainer h5{
    margin-bottom: 6px;
    
    
  } 
  .textMuted {
    padding: 5px; 
  
}

.dateContainer{
    padding-top: 0;
}