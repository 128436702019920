body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set minimum height to full viewport height */
  margin:0;
}

main {
  flex: 1; /* Allow the main content to grow and fill available space */
}
footer {
  width: 100%;
  bottom: 0;
  background: #0E5AFE ;
  color: #ccc;
  padding: 30px 0;
  border-top-right-radius: 125px;
  font-size: 13px;
  line-height: 20px;
  overflow-x: hidden;
  position: relative;
  margin-top: auto;

  
  
}

.row {
  width: 85%;
  margin: auto;
  margin-left: 10;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  flex: 1;
}

.col {
  flex-basis: 25%;
  padding: 10px;
}

.col:nth-child(2),
.col:nth-child(3) {
  flex-basis: 15%;
}

.logo {
  width: 200px;
  margin-bottom: 30px;
}

.col h3 {
  width: fit-content;
  margin-bottom: 40px;

  position: relative;
}

.email-id {
  width: fit-content;
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

ul li a {
  text-decoration: none;
  color: #ccc;
}

hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
}

.copyright {
  text-align: center;
}


@media (max-width: 700px) {
  footer {
    bottom: unset;
  }

  .col {
    flex-basis: 25%;
    padding: 10px;
  }

  .col:nth-child(2),
  .col:nth-child(3) {
    flex-basis: 100%;
  }
}

.social-icons {
  display: flex;
  gap: 20px;
  font-size: 24px; /* Adjust the font size to your preferred icon size */
}

.social-icons a {
  color: #fff;
  text-decoration: none;
}

ul{
  padding-left: 0; /* Remove default left padding */
  list-style: none;
}

.col p {
  margin: 0;
}