.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem ;
}

.blog-header {
  text-align: center;
}

.blog-header h3 {
  font-size: 60px;
  color: #0f0808; 
  margin: 0;
  padding: 80px 10px 20px; /* Adjusted padding (top, right, bottom) */
   
}

.container {
  display: flex;
  justify-content: center;
  padding: 20px; /* Adjust the padding as needed */
  width: 100%;
}
