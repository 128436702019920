.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Adjust the height as needed */
}

.image-container {
  position: relative;
  overflow: hidden;
} 

.image-container img {
  max-width: 100%;
  height: auto;
  margin: 10px;
  transition: transform 0.3s ease; /* Add a smooth transition effect for transform */
}

.two-column-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  perspective: 1000px;
}

.two-column-container:hover .image-column {
  transform: translateZ(-50px) rotateY(5deg); 
}

.text-column {
  flex: 1;
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.two-column-container:hover .text-column {
  transform: scale(1.05); 
}

.image-column {
  flex: 1;
  transform: translateZ(0);
  transition: transform 0.3s ease;
}


.text-centered {
  text-align: center;
}


.product-li {
  font-size: 22px;
  /* list-style-type: disc; */
  transition: transform 0.3s ease;
}

.product-li:hover {
  transform: scale(1.05);
}

