
body {
  font-size: 16px;
}


.dashboard-container{
  width: 100%;
  max-width: 1200px; /* Set a max-width if needed */
  margin: 0 auto; /* Center the container */
  flex-direction: column;
  margin-top: -50px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  min-height: 100vh;
  margin-left: 50px;
  
}
@media (max-width: 768px) {
  .dashboard-container {
      margin-top: 60px; /* Adjust top margin for smaller screens */
      margin-bottom: 80px; /* Adjust bottom margin for smaller screens */
  }
}

@media (max-width: 600px) {
  .dashboard-container {
      margin-top: 40px; /* Further adjust top margin for even smaller screens */
      margin-bottom: 60px; /* Further adjust bottom margin for even smaller screens */
  }
}