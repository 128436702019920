.login-container {
  text-align: center;
  margin-top: 15rem;
  min-height: 100vh;
}

.login-heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input {
  margin: 0.5rem 0;
}

.login-form .login-button {
  margin-top: 1.2rem; 
  width: 100%; /* Set the width to 100% */
}

.login-error {
  color: red;
  margin-top: 0.5rem;
}

.login-container .login-info {
  margin-top: 2rem ;
}

.login-container .login-description {
  margin: 1.8rem 1.8rem ;
}
